
import { Vue, Component } from 'vue-property-decorator';
import LandingHero from '@/components/landingAndRegistrationComponents/LandingHero.vue';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import LandingLabel from '@/components/landingAndRegistrationComponents/LandingLabel.vue';
import InsightsWhite from '@/components/creditpushMain/HomePage/InsightsWhite.vue';
import LandingFeatureGrid from '@/components/landingAndRegistrationComponents/LandingFeatureGrid.vue';
import LandingOnboardingFlow from '@/components/landingAndRegistrationComponents/LandingOnboardingFlow.vue';
import { getNextPage } from '@/utils/redirectUtils';

@Component({
  components: {
    LandingHero,
    BaseButton,
    LandingLabel,
    InsightsWhite,
    LandingFeatureGrid,
    LandingOnboardingFlow,
  },
})
export default class LandingCashManagementPage extends Vue {
  landingFeatures: any[] = [
    {
      title: 'Improving financial visibility',
      description: null,
      icon: 'improving-finance',
    },
    {
      title: 'Enabling deeper spending analysis',
      description: null,
      icon: 'spending-analysis',
    },
    {
      title: 'Convenient and visual payment calendar',
      description: null,
      icon: 'doc',
    },
    {
      title: 'Actionable alerts',
      description: null,
      icon: 'alerts',
    },
  ];
  violetShadow = '#532cff60';
  greenShadow = '#34be5280';
  goToRegistration(): void {
    this.$router.push({ name: getNextPage()});
  }

  mounted(): void {
    this.$store.commit('flowStages/setCurrentFlowAndStage', {flowVersion: '600', pageName: this.$route.name});
  }
}
