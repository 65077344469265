
import { Vue, Component } from 'vue-property-decorator';
import BaseButton from '@/components/baseComponents/BaseButton.vue';

@Component({
  components: { BaseButton },
})
export default class LandingHero extends Vue {

}
